$white: #ffffff;

* {
    font-family: 'Roboto', sans-serif;
}

a:not([class]) {
    text-decoration: none;
}

a:not([class]):hover {
    text-decoration: underline;
}

/*
*** Top bar
 */

#topbar {
    background: #fff;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    font-size: 14px;
}

#topbar .contact-info a {
    line-height: 1;
    color: #444;
    transition: 0.3s;
}

#topbar .contact-info a:hover {
    color: #428bca;
}

#topbar .contact-info i {
    color: #428bca;
    padding: 4px;
}

#topbar .contact-info .icofont-phone {
    padding-left: 20px;
    margin-left: 10px;
}

#topbar .social-links a {
    color: #5c768d;
    padding: 4px 12px;
    display: inline-block;
    line-height: 1px;
    transition: 0.3s;
}

#topbar .social-links a:hover {
    color: #428bca;
}

/*
*** Header
 */

 .header.home {
    min-height: 600px;
    background-color: #82879b;
    background-image: url('/images/layout/website/home/colloquiamo_header_big.jpg'); /* Fallback JPG */
    background-repeat: no-repeat;
}

/* Se il browser supporta WebP, lo usa al posto del JPG */
@supports (background-image: url('/images/layout/website/home/colloquiamo_header_big.webp')) {
    .header.home {
        background-image: url('/images/layout/website/home/colloquiamo_header_big.webp');
    }
}


.header.home {
    & h1 {
        color: $white;
        font-weight: 900;
    }

    & h3 {
        color: $white;
    }

    .hero-waves {
        display: block;
        width: 100%;
        height: 120px;
        position: absolute;
        bottom: -5px;
    }

    .wave1 use {
        -webkit-animation: move-forever1 10s linear infinite;
        animation: move-forever1 10s linear infinite;
        -webkit-animation-delay: -2s;
        animation-delay: -2s;
    }

    .wave2 use {
        -webkit-animation: move-forever2 8s linear infinite;
        animation: move-forever2 8s linear infinite;
        -webkit-animation-delay: -2s;
        animation-delay: -2s;
    }

    .wave3 use {
        -webkit-animation: move-forever3 6s linear infinite;
        animation: move-forever3 6s linear infinite;
        -webkit-animation-delay: -2s;
        animation-delay: -2s;
    }

    @-webkit-keyframes move-forever1 {
        0% {
            transform: translate(85px, 0%);
        }
        100% {
            transform: translate(-90px, 0%);
        }
    }

    @keyframes move-forever1 {
        0% {
            transform: translate(85px, 0%);
        }
        100% {
            transform: translate(-90px, 0%);
        }
    }

    @-webkit-keyframes move-forever2 {
        0% {
            transform: translate(-90px, 0%);
        }
        100% {
            transform: translate(85px, 0%);
        }
    }

    @keyframes move-forever2 {
        0% {
            transform: translate(-90px, 0%);
        }
        100% {
            transform: translate(85px, 0%);
        }
    }

    @-webkit-keyframes move-forever3 {
        0% {
            transform: translate(-90px, 0%);
        }
        100% {
            transform: translate(85px, 0%);
        }
    }

    @keyframes move-forever3 {
        0% {
            transform: translate(-90px, 0%);
        }
        100% {
            transform: translate(85px, 0%);
        }
    }
}

.custom-shape-divider-bottom-1607470480 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1607470480 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
}

.custom-shape-divider-bottom-1607470480 .shape-fill {
    fill: #FFFFFF;
}

/*
*** Post Row
 */

.posts {
    & .card p {
        font-size: .7rem;
    }

    & .posts__meta {
        & i {
            color: green;
            font-size: 12px;
        }
    }
}

@media (max-width: 991px) {
    #next_nav {
        position: absolute;
        right: 0px;
        cursor: pointer;
        z-index: 1000;
        top: 50%;

        & i {
            -webkit-animation: linear infinite alternate;
            -webkit-animation-name: run;
            -webkit-animation-duration: .5s;
        }

        @-webkit-keyframes run {
            0% {
                transform: translateX(0px);
            }
            100% {
                transform: translateX(10px);
            }
        }
    }

    .scrolling-wrapper-flexbox {
        -webkit-overflow-scrolling: touch;
        display: flex !important;
        flex-wrap: nowrap !important;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .card {
            flex: 0 0 auto;
        }
    }
}


.card__wrapper {
    position: relative;

    &:hover .post__info {
        display: block;
        -webkit-animation: fadeInFromNone 0.5s ease-out;
        -moz-animation: fadeInFromNone 0.5s ease-out;
        -o-animation: fadeInFromNone 0.5s ease-out;
        animation: fadeInFromNone 0.5s ease-out;
    }
}

@media (max-width: 991px) {
    .post__info {
        box-shadow: none !important;
        display: none !important;
    }
}

.post__info {
    padding: 25px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: 0 10px 30px 11px rgba(0, 0, 0, .25);
    z-index: 100;
    display: none;

    &__description {
        color: #777;
        font-size: 14px;
    }

    &__add_wish_list {
        color: #b1b1b1;
        font-size: 12px;
    }
}

.post__listing__cover_image {
    min-height: 220px;
    object-fit: cover;
}

/*
*** Post Row Animation
 */

@-webkit-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-moz-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-o-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

/*
** PostCasts Listing
 */

.podcasts_listing {
    color: white;
    background-color: #293036;
    height: 700px;
    background-image: url('/images/layout/website/home/domande_colloquio_bg_podcasts_listing.jpg');
    background-size: cover;
}

.podcasts_logo_wrapper img {
    filter: grayscale(100%);
}

@media (max-width: 991px) {
    .podcasts_listing {
        background-image: url('/images/layout/website/home/domande_colloquio_bg_podcasts_listing_mobile.jpg') !important;
    }
}

.website_postcad_cover_image {
    object-fit: cover;
    width: 1100px;
    height: 473px;
}

/*
** Follow our Podcasts
 */

.follow_our_podcasts {
    color: white;
    background-color: #293036;
    height: 500px;
    background-image: url('/images/layout/website/home/domande_colloquio_bg_follow_us_podcasts.jpg');
    background-size: cover;
}

/*
** Questions Categories
 */

.questions_categories {
    &__button {
        position: relative;
        padding: 16px 32px;
        display: block;
        //border: 1px solid #e4e6e8;
        border-radius: 33px;
        background-color: #f6f8fa;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #273044;
    }

    &__element__icon {
        width: 50px;
        height: 50px;
        background-color: blue;
        color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        font-weight: bold;
        justify-content: center;
    }

    & p {
        color: #777;
        font-size: .8rem;
    }

    & h5 {
        color: #222;
    }
}

/*
** Login Modal
 */

#registrationModal {
    background-image: url('/images/layout/website/bg_registration_modal.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .col-xl-6:first-child {
        background-color: #282828;
        overflow: hidden;
        color: #F2F2F2;
    }

    .col-xl-6:first-child .ragazza_img {
        position: absolute;
        bottom: 0;
        right: 11rem;
    }

    .col-xl-6:last-child {
        overflow: hidden;
    }

    .col-xl-9 input {
        border: 1.5px solid #26ae61;
        padding: 1.3rem;
    }

    .col-xl-8 p {
        font-size: .9rem;
        text-align: center;
        color: #26ae61;
    }

    .col-xl-8 hr {
        border: .7px solid #26ae61;
        width: 90%;
        margin: 0 auto;
    }

    .col-xl-9 button {
        background-color: #26ae61;
    }

    .display-4 {
        font-size: 2rem;
    }

    /* MOBILE */
    @media screen and (max-width: 700px) {

        .modal-dialog {
            max-width: 100% !important;
        }

        .col-xl-6:first-child {
            background-color: #282828;
            overflow: hidden;
            color: #F2F2F2;
        }

        .col-xl-6:first-child .ragazza_img {
            position: absolute;
            bottom: 0;
            right: 7.2rem;
            max-width: 400px;
            overflow: hidden;
        }

        .col-xl-6:last-child {
            overflow: hidden;
        }

        .title_personali {
            background-color: transparent;
            text-align: center;
            color: #282828;
            font-weight: 600;
            font-size: 1.7rem;
        }

        .col-xl-8 hr {
            border: .5px solid #26ae61;
            width: 90%;
            margin: 0 auto;
        }
    }
}

/*
** FAQ
 */

.btn-collpase-title {
    font-size: 1.2rem;
    padding-left: 0;
}


/*
** Hero Section
 */

#hero {
    background: #f9f9f9;
}

#hero .container {
    //padding-top: 54px;
}

#hero h1 {
    margin: 0;
    font-size: 42px;
    font-weight: 700;
    line-height: 56px;
    color: #0f394c;
    text-transform: uppercase;
}

#hero h2 {
    color: #1c698c;
    margin: 10px 0 0 0;
    font-size: 20px;
}

#hero .btn-get-started {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 28px;
    border-radius: 3px;
    transition: 0.5s;
    margin-top: 25px;
    color: #fff;
    background: #49b5e7;
    text-transform: uppercase;
}

#hero .btn-get-started:hover {
    background: #76c7ed;
}

@media (max-width: 991px) {
    #hero {
        //height: 100vh;
        text-align: center;
    }
    #hero .hero-img img {
        width: 50%;
    }
}

@media (max-width: 768px) {
    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }
    #hero h2 {
        font-size: 18px;
        line-height: 24px;
    }
    #hero .hero-img img {
        width: 80%;
    }
}

/*
** About Section
 */

.about .icon-boxes h3 {
    font-size: 28px;
    font-weight: 700;
    color: #0f394c;
    margin-bottom: 15px;
}

.about .icon-box {
    margin-top: 40px;
}

.about .icon-box .icon {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border: 2px solid #d0ecf9;
    border-radius: 50px;
    transition: 0.5s;
}

.about .icon-box .icon i {
    color: #49b5e7;
    font-size: 32px;
}

.about .icon-box:hover .icon {
    background: #49b5e7;
    border-color: #49b5e7;
}

.about .icon-box:hover .icon i {
    color: #fff;
}

.about .icon-box .title {
    margin-left: 85px;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 18px;
}

.about .icon-box .title a {
    color: #343a40;
    transition: 0.3s;
}

.about .icon-box .title a:hover {
    color: #49b5e7;
}

.about .icon-box .description {
    margin-left: 85px;
    line-height: 24px;
    font-size: 14px;
}

@media (min-width: 1200px) {
    .about .video-box {
        margin-left: 15px;
        margin-right: -15px;
    }
}

.about .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#49b5e7 50%, rgba(73, 181, 231, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
}

.about .play-btn::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.about .play-btn::before {
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate-btn 2s;
    animation: pulsate-btn 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(73, 181, 231, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
}

.about .play-btn:hover::after {
    border-left: 15px solid #49b5e7;
    transform: scale(20);
}

.about .play-btn:hover::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    -webkit-animation: none;
    animation: none;
    border-radius: 0;
}

@-webkit-keyframes pulsate-btn {
    0% {
        transform: scale(0.6, 0.6);
        opacity: 1;
    }
    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
}

@keyframes pulsate-btn {
    0% {
        transform: scale(0.6, 0.6);
        opacity: 1;
    }
    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
}


/**
* CV SERVICES
 */

.cv_services {
    h2 {
        font-weight: bold;
        color: #42495B;
        font-size: 2.3rem;
    }

    h3 {
        color: white;
    }

    small {
        font-weight: bold;
        color: #7042E1;
    }

    h4 {
        font-size: 1rem;
        font-weight: 700;
    }

    div.price, div.form {
        background-color: #7042E1;
        color: #222;
    }

    div.price .card {
        text-align: center;
        border-radius: 25px;
        -webkit-box-shadow: 0px 14px 40px -1px rgba(0, 0, 0, 0.22);
        -moz-box-shadow: 0px 14px 40px -1px rgba(0, 0, 0, 0.22);
        box-shadow: 0px 14px 40px -1px rgba(0, 0, 0, 0.22);
    }

    div.price h2 {
        color: #222;
    }

    div.price p.description {
        color: #7A839E;
    }

    .price p.lead {
        font-size: 1.5rem;
    }

    .price p.price {
        font-size: 1.7rem;
        font-weight: bold;
        color: #7042E1;
    }

    p {
        font-size: 1rem;
        margin-top: 30px;
        color: #7A839E;
    }

    div.our-method {
        background-color: #26AE61;
        text-align: left;
    }

    div.our-method p, div.our-method h2, div.our-method small, div.our-method li {
        color: white !important;
    }

    .faq.accordion .card-header {
        background-color: white;
    }

    .faq.accordion .card {
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, .125);
    }

    div.services, footer {
        background-color: #282828;
    }

    div.services, footer h4, p, h2 {
        color: white;
    }

    div.team {
        background-color: #f2f5fa;
    }

    div.header {
        background-color: #282828;
    }

    div.price {
        background-image: url('/images/landing/cv_services/domande_colloquio_price_bg.jpg');
        background-size: cover;
        background-position: center;
    }

    div.header p, h2 {
        color: white;
    }

    div.form h2, div.form p {
        color: white;
    }

    .btn.focus, .btn:focus {
        outline: 0;
        box-shadow: 0 0 0 0 !important;
    }

    header {
        background-color: white;
    }

    .custom-shape-divider-top-1606339148 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
    }

    .custom-shape-divider-top-1606339148 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 30px;
    }

    .custom-shape-divider-top-1606339148 .shape-fill {
        fill: #FFFFFF;
    }

    .custom-shape-divider-bottom-1606339207 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }

    .custom-shape-divider-bottom-1606339207 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 30px;
    }

    .custom-shape-divider-bottom-1606339207 .shape-fill {
        fill: #FFFFFF;
    }

    .aboutus {
        background-color: white;
    }

    .aboutus p, h2 {
        color: #222;
    }

    .stats {
        color: #222;
    }

    .stats h2 {
        font-weight: 500;
        font-size: 3rem;
        color: #222222;
    }

    .stats p {
        color: #222222;
    }

    .custom-shape-divider-top-1606430659 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 35px;
    }

    .custom-shape-divider-top-1606430659 .shape-fill {
        fill: #FFFFFF;
    }

    section.pricing {
        background: #C2C2C2;
        background: linear-gradient(to right, #C2C2C2, #C2C2C2);
    }

    .pricing .card {
        border: none;
        border-radius: 1rem;
        transition: all 0.2s;
        box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    }

    .pricing hr {
        margin: 1.5rem 0;
    }

    .pricing .card-title {
        margin: 0.5rem 0;
        font-size: 0.9rem;
        letter-spacing: .1rem;
        font-weight: bold;
    }

    .pricing .card-price {
        font-size: 3rem;
        margin: 0;
    }

    .pricing .card-price .period {
        font-size: 0.8rem;
    }

    .pricing ul li {
        margin-bottom: 1rem;
    }

    .pricing .text-muted {
        opacity: 0.7;
    }

    .pricing .btn {
        font-size: 80%;
        border-radius: 5rem;
        letter-spacing: .1rem;
        font-weight: bold;
        padding: 1rem;
        opacity: 0.7;
        transition: all 0.2s;
    }

    /* Hover Effects on Card */

    @media (min-width: 992px) {
        .pricing .card:hover {
            margin-top: -.25rem;
            margin-bottom: .25rem;
            box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
        }
        .pricing .card:hover .btn {
            opacity: 1;
        }
    }

    #emailResponse h3 {
        color: #222;
    }
}

.navbar-dark .navbar-nav .nav-link {
    color: white !important;
}

.navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.75) !important;
}

.nav-button {
    position: relative;
    z-index: 1111;
    border: solid 1px #f8f8f8;
    height: 42px;
    display: block;
    width: 50px;
    padding: 12px;
}

.nav-button #nav-icon3 {
    width: 24px;
    height: 22px;
    display: inline-block;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.nav-button #nav-icon3 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out
}

.nav-button #nav-icon3 span:nth-child(1) {
    top: 0
}

.nav-button #nav-icon3 span:nth-child(2), #nav-icon3 span:nth-child(3) {
    top: 7px
}

.nav-button #nav-icon3 span:nth-child(4) {
    top: 14px
}

.nav-open #nav-icon3 span:nth-child(1) {
    top: 9px;
    width: 0;
    left: 50%
}

.nav-open #nav-icon3 span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg)
}

.nav-open #nav-icon3 span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.nav-open #nav-icon3 span:nth-child(4) {
    top: 9px;
    width: 0;
    left: 50%
}

.flex-center {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%
}

/**
* VIDEO MODAL
 */

.modal-dialog-video {
    max-width: 800px;
    margin: 30px auto;
}

.modal-body-video {
    position: relative;
    padding: 0px;
}

.close-video {
    position: absolute;
    right: -30px;
    top: 0;
    z-index: 999;
    font-size: 2rem;
    font-weight: normal;
    color: #fff;
    opacity: 1;
}

/**
* PRICING LISTING
 */

$colorPlanBasic: #fe397a;
$colorPlanPro: #10bb87;
$colorPlanEnterprise: #5d78ff;

@mixin boxShadow {
    box-shadow: 0 2px 40px 0 rgba(205, 205, 205, 0.55);
}

body {
    font-family: 'Karla', sans-serif;
}

.pricing-table-subtitle {
    margin-top: 68px;
    font-weight: normal;
}

.pricing-table-title {
    font-weight: bold;
    margin-bottom: 68px;
}

.pricing-card {
    border: none;
    border-radius: 10px;
    margin-bottom: 40px;
    text-align: center;
    transition: all 0.6s;

    &:hover {
        @include boxShadow();
    }

    &.pricing-card-highlighted {
        @include boxShadow();
    }

    &:hover {
        @include boxShadow();

        transform: translateY(-10px);
    }

    .card-body {
        padding-top: 55px;
        padding-bottom: 62px;
    }
}

.pricing-plan {
    &-title {
        font-size: 20px;
        color: #000;
        margin-bottom: 11px;
        font-weight: normal;
    }

    &-cost {
        font-size: 50px;
        color: #000;
        font-weight: bold;
        margin-bottom: 29px;

    }

    &-icon {
        display: inline-block;
        width: 40px;
        height: 40px;
        font-size: 40px;
        line-height: 1;
        margin-bottom: 24px;
    }

    &-features {
        list-style: none;
        padding-left: 0;
        line-height: 2.14;
        color: #303132;

    }

    &-purchase-btn {
        color: #000;
        font-size: 16px;
        font-weight: bold;
        width: 145px;
        height: 45px;
        border-radius: 22.5px;
        transition: all 0.4s;
        position: relative;
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;

        .pricing-plan-basic & {
            background-color: $colorPlanBasic;
            color: #fff;

            &:hover {
                box-shadow: 0 3px 0 0 darken($colorPlanBasic, 25%);
            }

            &:active {
                transform: translateY(3px);
                box-shadow: none;
            }
        }

        .pricing-plan-pro & {
            background-color: $colorPlanPro;
            color: #fff;

            &:hover {
                box-shadow: 0 3px 0 0 darken($colorPlanPro, 15%);
            }

            &:active {
                transform: translateY(3px);
                box-shadow: none;
            }
        }

        .pricing-plan-enterprise & {
            background-color: $colorPlanEnterprise;
            color: #fff;

            &:hover {
                box-shadow: 0 3px 0 0 darken($colorPlanEnterprise, 15%);
            }

            &:active {
                transform: translateY(3px);
                box-shadow: none;
            }
        }
    }
}

/**
* UTILITY
 */

#g-recaptcha-response {
    display: block !important;
    position: absolute;
    margin: -78px 0 0 0 !important;
    width: 302px !important;
    height: 76px !important;
    z-index: -999999;
    opacity: 0;
}

.modal.fade {
    z-index: 10000000 !important;
}

/**
* USER PROFILE
 */

.userProfile {
    html {
        color: #1a202c;
        text-align: left;
        background-color: #e2e8f0;
    }

    .card {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
    }

    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 0 solid rgba(0, 0, 0, .125);
        border-radius: .25rem;
    }

    .card-body {
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1rem;
    }

    .gutters-sm {
        margin-right: -8px;
        margin-left: -8px;
    }

    .gutters-sm > .col, .gutters-sm > [class*=col-] {
        padding-right: 8px;
        padding-left: 8px;
    }

    .mb-3, .my-3 {
        margin-bottom: 1rem !important;
    }
}

/**
* CHECKOUT
 */

.faq__row p {
    margin-bottom: 30px;
}

.StripeElement {
    box-sizing: border-box;
    height: 40px;
    padding: 10px 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

/**
* DOMANDE E RISPOSTE
 */

.questionsAnswers {
    .hero {
        width: 100%;
        /*height: 100vh;*/
        background-size: cover;
    }

    .hero h1 {
        margin: 0;
        font-size: 38px;
        font-weight: 700;
        color: #012970;
    }

    .hero h2 {
        color: #444444;
        margin: 15px 0 0 0;
        font-size: 26px;
    }

    .hero .btn-get-started {
        margin-top: 30px;
        line-height: 0;
        padding: 15px 40px;
        border-radius: 4px;
        transition: 0.5s;
        color: #fff;
        background: #4154f1;
        box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
    }

    .hero .btn-get-started span {
        font-family: "Nunito", sans-serif;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 1px;
    }

    .hero .btn-get-started i {
        margin-left: 5px;
        font-size: 18px;
        transition: 0.3s;
    }

    .hero .btn-get-started:hover i {
        transform: translateX(5px);
    }

    .hero .hero-img {
        text-align: right;
    }

    @media (min-width: 1024px) {
        .hero {
            background-attachment: fixed;
        }
    }

    @media (max-width: 991px) {
        .hero {
            height: auto;
        }

        .hero .hero-img {
            text-align: center;
            margin-top: 80px;
        }

        .hero .hero-img img {
            width: 80%;
        }
    }

    @media (max-width: 768px) {
        .hero {
            text-align: center;
        }

        .hero h1 {
            font-size: 32px;
        }

        .hero h2 {
            font-size: 24px;
        }

        .hero .hero-img img {
            width: 100%;
        }
    }

    .counts {
        padding: 70px 0 60px;
    }

    .counts .count-box {
        display: flex;
        align-items: center;
        padding: 30px;
        width: 100%;
        background: #fff;
        box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
    }

    .counts .count-box i {
        font-size: 42px;
        line-height: 0;
        margin-right: 20px;
        color: #4154f1;
    }

    .counts .count-box span {
        font-size: 36px;
        display: block;
        font-weight: 600;
        color: #0b198f;
    }

    .counts .count-box p {
        padding: 0;
        margin: 0;
        font-family: "Nunito", sans-serif;
        font-size: 14px;
    }

    .goToJobListing i {
        transition: 0.5s;
    }

    .goToJobListing:hover i {
        transform: translateX(5px);
    }
}

.questionsAnswersCategory {
    .card-header {
        padding: 0.75rem 1.25rem;
        margin-bottom: 0;
        background-color: transparent;
        border: none;
    }

    .accordion > .card {
        border: none;
        padding: 5px 0 5px 0;
    }

    .accordion, .btn-link:focus, .btn-link.focus {
        outline: none;
        box-shadow: none;
    }

    .sidebar-box .read-more {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 30px 0;

        /* "transparent" only works here because == rgba(0,0,0,0) */
        background-image: linear-gradient(to bottom, transparent, white);
    }

    .custom-shape-divider-bottom-1609221161 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }

    .custom-shape-divider-bottom-1609221161 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 55px;
    }

    .custom-shape-divider-bottom-1609221161 .shape-fill {
        fill: #f7f7f7;
    }

    .title_single {
        font-size: 3rem;
        font-family: 'Karla', sans-serif;
    }

    body {
        background-color: #f7f7f7 !important;
    }

    .btn-circle {
        width: 30px;
        height: 30px;
        padding: 6px 0px;
        border-radius: 15px;
        text-align: center;
        font-size: 12px;
    }

    .hero-waves {
        display: block;
        width: 100%;
        height: 100px;
        position: absolute;
        bottom: -5px;
    }

    .wave1 use {
        -webkit-animation: move-forever1 10s linear infinite;
        animation: move-forever1 10s linear infinite;
        -webkit-animation-delay: -2s;
        animation-delay: -2s;
    }

    .wave2 use {
        -webkit-animation: move-forever2 8s linear infinite;
        animation: move-forever2 8s linear infinite;
        -webkit-animation-delay: -2s;
        animation-delay: -2s;
    }

    .wave3 use {
        -webkit-animation: move-forever3 6s linear infinite;
        animation: move-forever3 6s linear infinite;
        -webkit-animation-delay: -2s;
        animation-delay: -2s;
    }

    @-webkit-keyframes move-forever1 {
        0% {
            transform: translate(85px, 0%);
        }
        100% {
            transform: translate(-90px, 0%);
        }
    }

    @keyframes move-forever1 {
        0% {
            transform: translate(85px, 0%);
        }
        100% {
            transform: translate(-90px, 0%);
        }
    }

    @-webkit-keyframes move-forever2 {
        0% {
            transform: translate(-90px, 0%);
        }
        100% {
            transform: translate(85px, 0%);
        }
    }

    @keyframes move-forever2 {
        0% {
            transform: translate(-90px, 0%);
        }
        100% {
            transform: translate(85px, 0%);
        }
    }

    @-webkit-keyframes move-forever3 {
        0% {
            transform: translate(-90px, 0%);
        }
        100% {
            transform: translate(85px, 0%);
        }
    }

    @keyframes move-forever3 {
        0% {
            transform: translate(-90px, 0%);
        }
        100% {
            transform: translate(85px, 0%);
        }
    }
}

.postShow {
    .website_post_show_text {
        margin: 0 0 35px;
        font-size: 1.1rem;
        line-height: 2.3rem;
        color: #222;
        padding-top: 20px;
    }

    h1 {
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
        color: #343a40;
        font-size: 2.0rem;
        font-weight: 600;
    }

    .dot {
        width: 35px;
        height: 35px;
        background-color: #fafafa;
        border-radius: 50%;
        position: relative;
    }

    ul.website_post_sidebar_meta_info_show li {
        background-color: transparent;
        border: 1px solid rgb(202 202 202 / 13%);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.25rem 1.25rem
    }

    ul.website_post_sidebar_meta_info_show li i {
        color: #385bce;
    }

    .rating {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center
    }

    .rating > input {
        display: none
    }

    .rating > label {
        position: relative;
        width: .8em;
        font-size: 1.5rem;
        color: #FFD600;
        cursor: pointer;
        margin-bottom: 0;
    }

    .rating > label::before {
        content: "\2605";
        position: absolute;
        opacity: 0
    }

    .rating > label:hover:before,
    .rating > label:hover ~ label:before {
        opacity: 1 !important
    }

    .rating > input:checked ~ label:before {
        opacity: 1
    }

    .rating:hover > input:checked ~ label:before {
        opacity: 0.4
    }

    .nav.nav-tabs > li {
        flex: 1 0;
    }

    .nav.nav-tabs > li.active a {
        border-top-color: #385bce !important;
        background-color: #fff;
        color: #273044 !important;
    }

    .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
        color: #555;
        cursor: default;
        background-color: #fff;
        border: 1px solid #ddd;
        border-bottom-color: transparent;
    }

    .nav.nav-tabs > li a {
        padding: 16px 10px 20px;
        margin: 0;
        border: 0 !important;
        border-top: 3px solid #f0f4fa !important;
        border-radius: 0;
        font-size: 14px;
        font-weight: 500;
        background-color: #f0f4fa;
        text-align: center;
        color: #273044;
    }

    .nav-tabs .nav-item.show .nav-link {
        background-color: #f0f4fa;
    }

    .website_post_sidebar_meta_info_show_2 li {
        background-color: transparent;
        border: 0;
    }

    .website_post_sidebar_meta_info_show_2 .list-group-item:first-child {
        font-size: 15px;
        border-left: 3px solid orange;
        text-transform: uppercase;
        font-weight: 600;
    }

    .website_post_sidebar_meta_info_show_2 .list-group-item:not(:first-child) {
        border-left: 1px solid #d8d8d8;
        /*text-transform: uppercase;*/
    }

    .website_post_sidebar_meta_info_show_2 .list-group-item {
        padding: 0.5rem 1.25rem;
    }
}


.postsIndex {
    #hero {
        width: 100%;
        overflow: hidden;
        position: relative;
        background: linear-gradient(0deg, #2a2c39 0%, #33364a 100%);
        padding: 0;
    }

    #hero .carousel-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        position: relative;
        height: 50vh;
        padding-top: 60px;
    }

    #hero h2 {
        color: #fff;
        margin-bottom: 30px;
        font-size: 48px;
        font-weight: 700;
    }

    #hero p {
        width: 80%;
        -webkit-animation-delay: 0.4s;
        animation-delay: 0.4s;
        margin: 0 auto 30px auto;
        color: #fff;
    }

    #hero .carousel-control-prev, #hero .carousel-control-next {
        width: 10%;
    }

    #hero .carousel-control-next-icon, #hero .carousel-control-prev-icon {
        background: none;
        font-size: 48px;
        line-height: 1;
        width: auto;
        height: auto;
    }

    #hero .btn-get-started {
        font-family: "Raleway", sans-serif;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1px;
        display: inline-block;
        padding: 12px 32px;
        border-radius: 50px;
        transition: 0.5s;
        line-height: 1;
        margin: 10px;
        color: #fff;
        -webkit-animation-delay: 0.8s;
        animation-delay: 0.8s;
        border: 2px solid #ef6603;
    }

    #hero .btn-get-started:hover {
        background: #ef6603;
        color: #fff;
        text-decoration: none;
    }

    @media (min-width: 1024px) {
        #hero p {
            width: 60%;
        }
        #hero .carousel-control-prev, #hero .carousel-control-next {
            width: 5%;
        }
    }

    @media (max-width: 768px) {
        #hero .carousel-container {
            height: 90vh;
        }
        #hero h2 {
            font-size: 28px;
        }
    }

    .hero-waves {
        display: block;
        width: 100%;
        height: 60px;
        position: relative;
    }

    .wave1 use {
        -webkit-animation: move-forever1 10s linear infinite;
        animation: move-forever1 10s linear infinite;
        -webkit-animation-delay: -2s;
        animation-delay: -2s;
    }

    .wave2 use {
        -webkit-animation: move-forever2 8s linear infinite;
        animation: move-forever2 8s linear infinite;
        -webkit-animation-delay: -2s;
        animation-delay: -2s;
    }

    .wave3 use {
        -webkit-animation: move-forever3 6s linear infinite;
        animation: move-forever3 6s linear infinite;
        -webkit-animation-delay: -2s;
        animation-delay: -2s;
    }

    @-webkit-keyframes move-forever1 {
        0% {
            transform: translate(85px, 0%);
        }
        100% {
            transform: translate(-90px, 0%);
        }
    }

    @keyframes move-forever1 {
        0% {
            transform: translate(85px, 0%);
        }
        100% {
            transform: translate(-90px, 0%);
        }
    }

    @-webkit-keyframes move-forever2 {
        0% {
            transform: translate(-90px, 0%);
        }
        100% {
            transform: translate(85px, 0%);
        }
    }

    @keyframes move-forever2 {
        0% {
            transform: translate(-90px, 0%);
        }
        100% {
            transform: translate(85px, 0%);
        }
    }

    @-webkit-keyframes move-forever3 {
        0% {
            transform: translate(-90px, 0%);
        }
        100% {
            transform: translate(85px, 0%);
        }
    }

    @keyframes move-forever3 {
        0% {
            transform: translate(-90px, 0%);
        }
        100% {
            transform: translate(85px, 0%);
        }
    }

    .openBtn {
        background: #f1f1f1;
        border: none;
        padding: 10px 15px;
        font-size: 20px;
        cursor: pointer;
    }

    .openBtn:hover {
        background: #bbb;
    }

    .overlay {
        height: 100%;
        width: 100%;
        display: none;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.9);
    }

    .overlay-content {
        position: relative;
        top: 46%;
        width: 80%;
        text-align: center;
        margin-top: 30px;
        margin: auto;
    }

    .overlay .closebtn {
        position: absolute;
        top: 20px;
        right: 45px;
        font-size: 60px;
        cursor: pointer;
        color: white;
    }

    .overlay .closebtn:hover {
        color: #ccc;
    }

    .overlay input[type=text] {
        padding: 15px;
        font-size: 17px;
        border: none;
        float: left;
        width: 80%;
        background: white;
    }

    .overlay input[type=text]:hover {
        background: #f1f1f1;
    }

    .overlay button {
        float: left;
        width: 20%;
        padding: 15px;
        background: #ddd;
        font-size: 17px;
        border: none;
        cursor: pointer;
    }

    .overlay button:hover {
        background: #bbb;
    }
}

.podcastShow {
    .website_postcad_show {
        background: #343c42;
        color: white;
    }

    .rating {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center
    }

    .rating > input {
        display: none
    }

    .rating > label {
        position: relative;
        width: .8em;
        font-size: 1.5rem;
        color: #FFD600;
        cursor: pointer;
        margin-bottom: 0;
    }

    .rating > label::before {
        content: "\2605";
        position: absolute;
        opacity: 0
    }

    .rating > label:hover:before,
    .rating > label:hover ~ label:before {
        opacity: 1 !important
    }

    .rating > input:checked ~ label:before {
        opacity: 1
    }

    .rating:hover > input:checked ~ label:before {
        opacity: 0.4
    }
}

/**
* OUR SERVICES HOME
 */

.about .content h3 {
    font-weight: 600;
    font-size: 26px;
}

.about .content ul {
    list-style: none;
    padding: 0;
}

.about .content ul li {
    padding-left: 28px;
    position: relative;
}

.about .content ul li + li {
    margin-top: 10px;
}

.about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #47b2e4;
    line-height: 1;
}

.about .content p:last-child {
    margin-bottom: 0;
}

.about .content .btn-learn-more {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 4px;
    transition: 0.3s;
    line-height: 1;
    color: #47b2e4;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #47b2e4;
}

.about .content .btn-learn-more:hover {
    background: #47b2e4;
    color: #fff;
    text-decoration: none;
}

.about .section-title h2, .posts__header__title__questions {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 20px;
    position: relative;
    color: #37517e;

    &::after {
        content: '';
        position: absolute;
        display: block;
        width: 40px;
        height: 3px;
        background: #47b2e4;
        bottom: 0;
        left: calc(50% - 20px);
    }
}

.posts__header__title, .lastPodcastsTitle {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 20px;
    position: relative;
    color: #37517e;

    &::after {
        content: '';
        position: absolute;
        display: block;
        width: 40px;
        height: 3px;
        background: #47b2e4;
        bottom: 0;
    }
}

/*
*** Nav Item and Dropdown Colors
 */

.navbar-dark .navbar-nav .nav-link {
    color: white;
}

.dropdown-menu {
    background-color: #343a40 !important;
    border: 0;

    & .dropdown-item {
        color: white;

        &:hover {
            background-color: transparent;
            color: rgba(255, 255, 255, 0.75);
        }
    }
}


/*
*** Modal Full Screen
 */

.modal.modal-fullscreen .modal-dialog {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    max-width: none;
}

.modal.modal-fullscreen .modal-content {
    height: auto;
    height: 100vh;
    border-radius: 0;
    border: none;
}

.modal.modal-fullscreen .modal-body {
    overflow-y: auto;
}

.btn-success {
    background-color: #26ad61 !important;
    border-color: #26ad61 !important;
}

.btn-success:hover {
    background-color: #1b8c4b !important;
    border-color: #1b8c4b !important;
}
